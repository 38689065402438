import React, { Component } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { buildBlocks } from "../services/builder";
import { CoreFields } from "./fragment"; // eslint-disable-line
import { CoreBlockFragment } from "../components/blocks/CoreBlock"; // eslint-disable-line
import Breadcrumb from "../components/Breadcrumb";

/**
 * This page should query the posts based on the context provided and pass the results to the post-list block
 * No post-list block? Show an error
 */
class ListCaseStudy extends Component {
  render() {
    // console.log(this.props.data.allWpCaseStudy.edges);
    const blocks = buildBlocks(
      this.props.data.wpPage.blocks,
      {
        featuredPost: this.props.data.allWpCaseStudy.edges[0],
        // skip first post
        posts: this.props.data.allWpCaseStudy.edges,
      },
      {
        posts: this.props.data.allWpCaseStudy.edges,
        featuredPost: this.props.data.allWpCaseStudy.edges[0],
        ...this.props.pageContext,
      }
    );

    return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          canonical: this.props.data.wpPage.uri,
        }}
        path={this.props.pageContext.pagePath}
        title={this.props.data.wpPage.title}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <Breadcrumb
          type="post-list"
          current={{
            title: this.props.data.wpPage.title,
            slug: this.props.data.wpPage.slug,
            uri: this.props.data.wpPage.uri,
          }}
        />
        {blocks}
      </Layout>
    );
  }
}

export const query = graphql`
  query($id: String, $ids: [Int]) {
    wpPage(slug: { eq: $id }) {
      id
      slug
      title
      uri
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      ... on WpBlockEditorContentNode {
        blocks {
          ...CoreBlock
          ...CoreFields
        }
      }
    }
    allWpCaseStudy(filter: { databaseId: { in: $ids } }) {
      edges {
        node {
          id
          slug
          uri
          title
          date(formatString: "DD MMMM YYYY")
          excerpt
          featuredImage {
            node {
              mediaItemUrl
              altText
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    wpCaseStudy {
      id
      slug
      uri
      title
      date(formatString: "DD MMMM YYYY")
      excerpt
      featuredImage {
        node {
          mediaItemUrl
          altText
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default ListCaseStudy;
